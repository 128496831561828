import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import { useRouter } from 'next/router'
import { css } from '@emotion/react'

import { underlineHover } from '@/styles/mixins'

import Link from '@/components/common/Link'
import AccordionItem from '@/components/common/AccordionItem'

import { useFetchParkings } from '@/service/apiHooks/useFetchParkings'
import { useMarket } from '@/utils/multi-markets/context'

function FaqPriceList() {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()
  const { locale, market } = useMarket()
  const { parkings } = useFetchParkings({
    params: {
      lang: locale,
      size: 100,
      countryCode: market,
      sorting: 'distance;asc'
    }
  })

  const handleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <AccordionItem
      question={t('faq.faqPriceList.title')}
      idx={0}
      isOpen={isOpen}
      onClick={handleAccordion}
    >
      <div data-test-id="faq-pricing-list">
        {t('faq.faqPriceList.content')}
        <ul css={styles.list}>
          {parkings?.map((el, idx) => (
            <li key={idx}>
              <Link
                href={{ query: { ...router.query, pricing: el.urlSlug } }}
                replace
                shallow
                passHref
              >
                <a css={styles.link}>
                  {el?.translationKeys?.parkingLotName ?? el.shortDisplayName}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </AccordionItem>
  )
}

const styles = {
  list: css`
    margin: 1em 0;
    list-style: disc inside;
    padding: 0 20px;
  `,
  link: css`
    ${underlineHover};
  `
}

export default FaqPriceList
